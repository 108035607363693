/* Header */

.header {
  display: flex;
  max-width: 1440px;
  width: 100%;
  height: 40px;
  inset: 0;
  margin: 30px auto;
  align-items: center;
  padding: 0 60px;
  box-sizing: border-box;
}

/* Logo */

.logo {
  display: flex;
  align-items: center;
}

/* Main */

.main {
  max-width: 100vw;
  inset: 0;
  margin: 60px auto;
  padding: 0 60px;
  box-sizing: border-box;
}

/* Feature */

.feature {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
}

/* Input */

.input {
  display: block;
  width: 300px;
  border: 2px solid #666;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  box-sizing: border-box;
  line-height: 27px;
}

/* Frame 1 */

/* Frame 2 */

/* Frame 3 */

/* Frame 15 */

/* Frame 16 */

/* Frame 17 */

/* Frame 18 */

/* Frame 19 */

/* Frame 20 */

.frame20 {
  display: flex;
  max-width: 1200px;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

/* Button */

.button {
  line-height: 60px;
  padding: 10px 40px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 45px;
  background: #1e1e1e;
  color: #fff;
  font-weight: 700;
  font-size: 32px;
}

.button:hover {
  filter: brightness(0.8);
}

.button:active {
  background: #888;
  transform: translateY(2px);
}

/* Kaydet */

/* Form */

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
}

/* Footer */

.footer {
  height: 40px;
  background: #c1c1c1;
}

.footerContent {
  display: flex;
  max-width: 1440px;
  width: 100%;
  height: 100%;
  inset: 0;
  margin: 0 auto;
  padding: 0 60px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
}

.footerContent2 {
  display: flex;
  width: 24%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}
